<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col md="8" sm="12" lg="8" pa-2>
        <v-card>
          <v-card-text>
            <span class="title">Create a new agent</span>
            <v-divider></v-divider>
            <p class="mt-3 mb-4 subtitle-2">
              Please enter the details of the agent:
            </p>
            <v-form ref="form" v-model="valid" autocomplete="false">
              <p class="mt-3 mb-4 subtitle-2">
                Basic Details
              </p>
              <v-checkbox
                class="mt-0 mb-0"
                v-model="assignManager"
                label="Do you want to assign a Manager?"
              ></v-checkbox>
              <v-select
                v-if="assignManager"
                v-model="assigned"
                :items="allManagers"
                label="Select a manager"
                outlined
                :rules="rules.mandatoryObj"
              ></v-select>
              <v-text-field
                :rules="rules.email"
                label="Email Id of agent"
                v-model="email"
                outlined
              ></v-text-field>
              <v-text-field
                :rules="rules.name"
                label="Name of agent"
                v-model="name"
                outlined
              ></v-text-field>
              <v-text-field
                :rules="[...rules.username,...rules.mandatory]"
                label="Username"
                v-model="username"
                outlined
              ></v-text-field>
              <v-checkbox
                class="mt-0 mb-0"
                v-model="sendPasswordOnEmail"
                label="Do you want to send password over email?"
              ></v-checkbox>
              <v-text-field
                v-if="!sendPasswordOnEmail"
                :rules="rules.password"
                label="Password"
                :type="showPass ? 'text' : 'password'"
                :append-icon="showPass ? 'far fa-eye' : 'far fa-eye-slash'"
                @click:append="showPass = !showPass"
                v-model="password"
                outlined
                autocomplete="new-password"
              ></v-text-field>
              <v-text-field
                maxlength="10"
                counter
                :rules="rules.number"
                label="Phone number"
                type="text"
                v-model="phone"
                outlined
              ></v-text-field>
              <v-checkbox
                  class="mt-0 mb-0"
                  v-model="firstLogin"
                  label="First time password change needed for RE Agent?"
                ></v-checkbox>
              <p
                v-if="hasSkills"
                class="mt-3 mb-4 subtitle-2"
              >Skills</p>
              <v-select
                v-if="hasSkills && !skillError && skillSets.length > 0"
                v-model="assignedSkills"
                :items="skillSets"
                label="Assign a skill-tag to agent"
                multiple
              ></v-select>
              <p
                v-if="hasSkills && !skillError && skillSets.length < 1"
              >You don't have any skills added</p>
              <p
                v-if="hasSkills && skillError"
                style="color: red"
              >Couldn't fetch skills</p>
              <p class="mt-3 mb-4 subtitle-2">
                Additional Fields
              </p>
              <v-text-field
                label="Branch"
                type="text"
                v-model="additionalFields.branch.value"
                :rules="additionalFields.branch.rules"
                outlined
              ></v-text-field>
              <v-text-field
                label="Employee Code"
                type="text"
                v-model="additionalFields.employeeCode.value"
                :rules="additionalFields.employeeCode.rules"
                outlined
              ></v-text-field>
              <v-text-field
                label="Designation"
                type="text"
                v-model="additionalFields.designation.value"
                :rules="additionalFields.designation.rules"
                outlined
              ></v-text-field>
              <v-text-field
                label="Remarks"
                type="text"
                v-model="additionalFields.remark.value"
                outlined
              ></v-text-field>
              <div class="text-right">
                <v-btn :disabled="!valid" color="primary" @click="createAgent()"
                  >Create Agent</v-btn
                >
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="askPhoneNumber" persistent max-width="500">
      <v-card>
        <v-card-title class="title mb-3"
          >Please add your mobile number</v-card-title
        >
        <v-card-text>
          <v-text-field
            label="Mobile phone number"
            v-model="phone"
            type="text"
            counter
            :rules="phoneRules"
            maxlength="10"
            outlined
          ></v-text-field>
          <div class="text-right">
            <v-btn
              color="red darken-1"
              text
              @click="
                askPhoneNumber = false;
                phone = '';
              "
              >Cancel</v-btn
            >
            <v-btn color="green darken-1" text @click="updatePhone()"
              >Update</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { end_points, constants } from "@/config";
import { getSkills } from "@/flux/skills.flux";
import enLib from "crypto-js/aes";

export default {
  data: () => ({
    valid: false,
    showPass:false,
    currentUserData: {},
    allManagers: [],
    name: "",
    assignManager: false,
    email: "",
    username: "",
    password: "",
    sendPasswordOnEmail: false,
    firstLogin: false,
    phone: "",
    assigned: {},
    customerId: "",
    skillTag: "",
    assignedSkills:[],
    skillSets: [],
    skillError: false,
    hasSkills: false,
    additionalFields: {
      branch: {
        value: "",
        rules: [
          (v) => /^[a-zA-Z0-9, .]*$/.test(v) || `Can contain only numbers, alphabets, space, " , " and " . "` ,
          (v) => /^(([, .][a-zA-Z0-9 ])?[a-zA-Z0-9]*)*$/.test(v) || `Can't use space, " , " or " . " at the end of the field.`
          ]
      },
      employeeCode: {
        value: "",
        rules: [
          (v) => !v || (v.length <= 35) || "Maximum 35 characters are allowed",
          (v) => !v || /^[^\s_-][\s\S]*[^\s_-]$/.test(v) || "Can't use space, '-' and '_' at the start or end of the field",
          (v) => !v || (/^[a-zA-Z0-9][a-zA-Z0-9\s_-]*$/.test(v)) || "Only alphanumeric characters, space, '-' and '_' are allowed",
        ]
      },
      designation: {
        value: "",
        rules: [
          (v) => !v || v.length <= 50 || "Maximum 50 characters are allowed",
          (v) => !v || !/^\s|\s$/.test(v) || "Can't use space at the start or end of the field",
          (v) => !v || /^[a-zA-Z0-9][a-zA-Z0-9\s]*$/.test(v) || "Only alphanumeric characters and space are allowed",
        ]
      },
      remark: {
        value: "",
        rules: []
      },
    },
    phoneRules: [
      (v) => /^[6-9]{1}[0-9]{9}/.test(v) || "Not a proper mobile number!",
    ],
    askPhoneNumber: false,
    rules: {
      username: [
        v => !!v || "Field cannot be empty!",
        v => /^[a-zA-Z0-9_]+$/.test(v) || "Not a valid username!",
        v => v.length <= 35 || "Maximum 35 characters are allowed",
      ],
      mandatory: [v => /^.+$/.test(v) || "Field cannot be empty!"],
      mandatoryObj: [
        (v) => /^[0-9A-Za-z]{24}$/.test(v.id) || "Field cannot be empty!",
      ],
      number: [
        (v) => !!v || "Field cannot be empty!",
        (v) => /^[6-9]{1}[0-9]{9}/.test(v) || "Not a valid mobile number!",
      ],
      name: [
        (v) => !!v || "Field cannot be empty!",
        (v) => v.length <= 70 || "Maximum 70 characters are allowed",
        (v) =>
        /^[a-zA-Z][a-zA-Z\s]*$/.test(v) ||
          "Only alphabets are allowed in this field"
      ],
      email: [
        (v) => !!v || "Field cannot be empty!",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Not a proper email!",
      ],
      password: [
        v => !!v || "Field cannot be empty!",
        v =>  ((v.length >= 8) && /[a-z]/g.test(v) && /[A-Z]/g.test(v) && /[0-9]/g.test(v) && /[\_\$\#\@\^\!\*\+\)\(\{\}\'\\\?\/\<\>\.\, \^\[\]]/g.test(v))
          || "Password should be minimum 8 characters length including lowerCase, UpperCase, number and special character."
      ]
    },
  }),
  methods: {
    async updatePhone() {
      if (/^[6-9]{1}[0-9]{9}/.test(this.phone)) {
          let dataToPost = {
            phone: this.phone,
          };
          dataToPost = enLib.encrypt(JSON.stringify(dataToPost), this.$store.getters.envValues.otpEncKey).toString();

          let res = await axiosInstance.patch(
            end_points.get_customer_data(this.currentUserData.id),
          {
            dataToPost
          }
        );
        if (res.data) {
          this.askPhoneNumber = false;
          eventBus.$emit("vueSnack", "Successfully updated phone number!");
        } else {
          eventBus.$emit("vueSnack", "Something went wrong!");
        }
      } else {
        eventBus.$emit("vueSnack", "Not a valid phone number!");
      }
    },
    async getAllReUnderManager(customerId) {
      try {
        this.allManagers = (
          await axiosInstance.get(end_points.get_all_agents(customerId))
        ).data;
        this.allManagers = this.allManagers
          .filter((item) => item.belongsTo == 0 && (item.role == undefined || item.role !== "admin"))
          .map((item) => (item = { text: item.name, value: { ...item } }));
      } catch (error) {
        console.log(error);
        this.allManagers = [];
      }
    },
    isEmpty (str) {
      return !str.trim().length;
    },
    clearView() {
      this.username = "";
      this.email = "";
      this.name = "";
      this.phone = "";
      this.sendPasswordOnEmail = false;
      this.firstLogin = false;
      this.assigned = {};
      this.assignManager = false;
      this.skillTag = "";
      this.additionalFields.branch.value = ""
      this.additionalFields.employeeCode.value = ""
      this.additionalFields.designation.value = ""
      this.additionalFields.remark.value = ""
      this.$refs.form.reset();
    },
    async createAgent() {
      if (this.name && this.email && this.username) {
        try {
          let misData = {};
          for (let i in this.additionalFields) {
            if (this.additionalFields[i].value && !this.isEmpty(this.additionalFields[i].value)) {
              misData[i] = this.additionalFields[i].value
            }
          }
          let res = await axiosInstance.post(
            end_points.create_agent(this.customerId),
            {
              name: this.name,
              email: this.email,
              username: this.username,
              phone: this.phone,
              password: this.password || "RandomOne",
              firstLogin: this.firstLogin,
              sendPasswordOnEmail: this.sendPasswordOnEmail,
              belongsTo: this.assignManager ? this.assigned.id : undefined,
              assignedSkills: this.assignedSkills,
              skillTag: this.assignedSkills[0] ? this.assignedSkills[0]:"",
              misData: misData
            }
          );
          console.log(res);
          if (res.data) {
            eventBus.$emit("vueSnack", "Successfully created a agent!");
            this.clearView();
            let { token } = this.$store.getters.userData;
            this.getAllReUnderManager(this.customerId);
          } else {
            eventBus.$emit(
              "vueSnack",
              "Agent creation failed!"
            );
          }
        } catch (error) {
          console.log("Error while creating Agent: " , error)
          let errRes = error.response.data.error.details;
          let errorToShow = [];
          for (let i in errRes.messages) {
            errorToShow.push(errRes.messages[i]);
          }
          eventBus.$emit("vueSnack", errorToShow.join(", "));
        }
      } else {
        eventBus.$emit("vueSnack", "All the fields are requried!");
      }
    },
  },
  async created() {
    try {
      if (this.$store.getters.userData) {
        let userId;
        if (this.$store.getters.userData.customerData) {
          userId = this.$store.getters.userData.customerData.id;
        } else {
          userId = this.$store.getters.userData.userId
        }
        let { token } = this.$store.getters.userData;
        axiosInstance.defaults.headers.common["Authorization"] = token;
        let resp = (
          await axiosInstance.get(end_points.get_customer_data(userId))
        ).data;
        this.currentUserData = {
          ...this.$store.getters.userData,
          ...resp,
        };

        if ( this.currentUserData.customerData ) {
          this.customerId = this.currentUserData.customerData.id
        } else {
          this.customerId = this.currentUserData.userId
        }
        // Fetching SKills here
        await getSkills({customerId: this.customerId}).then((res) => {
          this.hasSkills = res.hasSkills ? res.hasSkills : false;
          this.skillError = !res.success;
          this.skillSets = res.skillSets ? res.skillSets : [];

        }).catch((err) => {
          console.log("CreateAgent: Error while checking getSkills for CustomerId: ", this.customerUserId+", Error: "+err);
          this.hasSkills = false;
          this.skillError = true;
          this.skillSets = [];
        })

        if (!this.currentUserData.phone) {
          //this.askPhoneNumber = true;
        }
        this.$store.commit("setUserData", this.currentUserData);
        await this.getAllReUnderManager(userId);
        // getting skills from customer data
      } else {
        this.$router.push("/admin");
      }
    } catch (error) {
      console.log(error);
      this.$router.push("/admin");
    }
  },
};
</script>

<style>
</style>
